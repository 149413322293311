import {Box, Typography} from '@mui/material'
import {isEmpty} from 'lodash'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {MaterialStorage} from '../../types'
import {SiloIndicator} from '../materials'

type SilosProps = {
  silos: MaterialStorage[]
  loading?: boolean
}

export const Silos: FC<SilosProps> = ({silos, loading}) => {
  const {t} = useTranslation()

  return (
    <Box display={'flex'} flexWrap={'wrap'}>
      {silos.map((silo, index) => (
        <Box key={silo.name} m={1} data-test-id={`silo-${silo.name}`}>
          <SiloIndicator
            id={index}
            height={120}
            level={silo.currentStorageLevel.level}
            deadStockLevel={silo.deadStock}
            minTargetLevel={silo.minimumStockLevel}
            capacity={silo.capacity}
            shouldShowName={true}
            siloName={silo.name}
            nameLabelVariant="caption"
            capacityLabelSX={{fontWeight: 400}}
          />
        </Box>
      ))}
      {isEmpty(silos) && !loading && (
        <Typography sx={{ml: 2, mb: 1}} variant="body2">
          {t('productionVolume.label.noData')}
        </Typography>
      )}
    </Box>
  )
}
